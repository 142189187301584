import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";
import { useEffect, useRef } from "react";

import { getCountByRequirementStatus } from "../../utils/getCountByRequirementStatus";
import { DocumentUIVariant } from "../constants";
import { type HcpRequirementStatus } from "../types";

interface LogRequirementReportProps {
  hcpRequirementStatus: HcpRequirementStatus;
  isLoadingHcpRequirementStatus: boolean;
  totalCountOfMandatoryRequirements: number;
}

export function useLogRequirementReport(props: LogRequirementReportProps) {
  const { totalCountOfMandatoryRequirements, isLoadingHcpRequirementStatus, hcpRequirementStatus } =
    props;

  const requirementReportIsLogged = useRef(false);

  useEffect(() => {
    if (
      totalCountOfMandatoryRequirements === 0 ||
      isLoadingHcpRequirementStatus ||
      requirementReportIsLogged.current
    ) {
      return;
    }

    const properties = getCountByRequirementStatus(hcpRequirementStatus);

    logEvent(APP_V2_USER_EVENTS.VIEWED_DOCUMENTS, {
      ...properties,
      ui_variant: DocumentUIVariant.V2,
    });
    requirementReportIsLogged.current = true;
  }, [isLoadingHcpRequirementStatus, totalCountOfMandatoryRequirements, hcpRequirementStatus]);
}
