import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";

import { type RequirementWorkflowType } from "../resources/requirements/constants";
import { type MissingDocumentsAlertSource } from "./constants";

interface DocumentsDropOffFlowLogViewedMissingDocumentsAlertProps {
  missingDocuments: Set<{ requirementWorkflowType: RequirementWorkflowType }>;
  shiftId: string;
  facilityId: string;
  documentsDropOffFlowId: string;
  source: MissingDocumentsAlertSource;
}

// Adding this for type inference on the returned set element
function getFirstSetValue<T>(set: Set<T>): T | undefined {
  const firstValue = set.values().next();
  return firstValue.done ? undefined : firstValue.value;
}

export function logDocumentsDropoffFlowViewedMissingDocumentsAlert(
  props: DocumentsDropOffFlowLogViewedMissingDocumentsAlertProps
) {
  const { missingDocuments, shiftId, facilityId, documentsDropOffFlowId, source } = props;
  const firstMissingDocument = getFirstSetValue(missingDocuments);

  logEvent(APP_V2_USER_EVENTS.DOCUMENTS_UPLOAD_FLOW_VIEWED_MISSING_DOCUMENTS_ALERT, {
    numberOfMissingDocuments: missingDocuments.size,
    shiftId,
    facilityId,
    singleMissingRequirementWorkflowType:
      missingDocuments.size === 1 ? firstMissingDocument?.requirementWorkflowType : undefined,
    missingDocumentsAlertSource: source,
    documentsDropOffFlowId,
  });
}
