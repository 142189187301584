import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";

import { type MissingDocumentsAlertSource } from "./constants";

interface DocumentsDropOffFlowLogClickedViewDocumentsProps {
  numberOfMissingDocuments: number;
  documentsDropOffFlowId: string;
  source: MissingDocumentsAlertSource;
}

export function logDocumentsDropoffFlowClickedViewDocuments(
  props: DocumentsDropOffFlowLogClickedViewDocumentsProps
) {
  const { numberOfMissingDocuments, documentsDropOffFlowId, source } = props;
  logEvent(
    APP_V2_USER_EVENTS.DOCUMENTS_UPLOAD_FLOW_MISSING_DOCUMENTS_ALERT_CLICKED_VIEW_DOCUMENTS,
    {
      numberOfMissingDocuments,
      source,
      documentsDropOffFlowId,
    }
  );
}
